import type { EntityView } from "$lib/server/bulk/view/entity_views_loader_service";
import type { CacheableEntity } from "$lib/server/entities/CacheableEntity";
import type { Entity } from "$lib/server/entities/Entity";
import type { EntityID, EntityKey, EntityType } from "$lib/server/entities/EntityType";
import { fetch_json_w_retry, type UTMParams } from "$lib/utils";
import * as devalue from 'devalue';
import type { EntityViewResponse } from "../../routes/(app)/entity_views.json/+server";

export function generate_view_entity_url(entity: Entity & {entity_key?: EntityKey}, 
                                         base_url: string, 
                                         group_uuid?: string,
                                         tracking?: UTMParams)
    : {url: URL, full_path: string} {

    let url: URL;
    if (entity.entity_type === 'track') {

        url = new URL(`/tracks/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'pool') {

        url = new URL(`/pools/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'discussion') {

        url = new URL(`/discussions/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'comment') {

        let target_entity: CacheableEntity;
        let base_comment_entity: any = entity; // TODO: Find a way of leveraging the type-system to eliminate type use of 'any'.
        if (base_comment_entity.pool) {
            target_entity = base_comment_entity.pool;
        } else if (base_comment_entity.workout) {
            target_entity = base_comment_entity.workout;
        } else if (base_comment_entity.track) {
            target_entity = base_comment_entity.track;
        } else if (base_comment_entity.discussion) {
            target_entity = base_comment_entity.discussion;
        } else if (base_comment_entity.track_group_share) {
            target_entity = base_comment_entity.track_group_share.track;
        } else if (base_comment_entity.pool_group_share) {
            target_entity = base_comment_entity.pool_group_share.pool;
        } else if (base_comment_entity.workout_group_share) {
            target_entity = base_comment_entity.workout_group_share.workout;
        } else {
            // console.error(entity);
            // console.error("-----------------");
            // console.error(base_comment_entity);
            throw new Error(`Unsupported comment subtype: ${base_comment_entity?.entity_type}`);
        }

        // console.log("--------> ", entity.entity_type, target_entity.entity_type, target_entity.entity_key);
        url = generate_view_entity_url(target_entity, base_url).url;
        url.searchParams.append('comment_uuid', entity.uuid);
    } else if (entity.entity_type === 'group') {

        url = new URL(`/groups/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'workout') {

        url = new URL(`/workouts/${entity.uuid}/view`, base_url);
    } else {
        throw new Error(`Unsupported entity type - ${entity?.entity_type}`);
    }

    if (tracking?.campaign) {
        url.searchParams.append('utm_campaign', tracking.campaign);
    }
    if (tracking?.source) {
        url.searchParams.append('utm_source', tracking.source);
    }
    if (tracking?.medium) {
        url.searchParams.append('utm_medium', tracking.medium);
    }
    if (tracking?.content) {
        url.searchParams.append('utm_content', tracking.content);
    }

    if (group_uuid) {
        url.searchParams.append('group_uuid', group_uuid);
    }

    /*
     * Including the 'entity_key' paremeter will instruct the client to attempt
     * to load the entity from their local store first. This is a performance
     * optimization to reduce the number of network requests.
     * 
     * Enabled by default.
     */
    let already_contains_entity_key = url.searchParams.has('entity_key');
    if (entity.entity_key && !already_contains_entity_key) {

        let entity_key_param = map_entity_key_to_string(entity.entity_key);
        url.searchParams.append('entity_key', entity_key_param);
    }

    return {url, full_path: `${url.pathname}${url.search}`};
}

export function map_entity_key_to_string(entity_key: EntityKey): string {

    return `${entity_key.entity_type}:${entity_key.uuid}:${entity_key.version}:${entity_key.instance_version ?? 0}`;
}

export function extract_entity_key_from_string(entity_key_string: string): EntityKey {

    let [entity_type, uuid, version, instance_version] = entity_key_string.split(':');
    return {entity_type: entity_type as EntityType, uuid, version: parseInt(version), instance_version: parseInt(instance_version)};
}

export async function fetch_entity_view(fetch: any, entity_id: EntityID, base_url: URL): Promise<EntityViewResponse> {

    // fetch pool
    let entity_view_url: URL = new URL("/entity_views.json", base_url);

    if (entity_id.entity_type === 'track') {
        entity_view_url.searchParams.append("track_uuid", entity_id.uuid);
    } else if (entity_id.entity_type === 'pool') {
        entity_view_url.searchParams.append("pool_uuid", entity_id.uuid);
    } else if (entity_id.entity_type === 'discussion') {
        entity_view_url.searchParams.append("discussion_uuid", entity_id.uuid);
    } else if (entity_id.entity_type === 'comment') {
        entity_view_url.searchParams.append("comment_uuid", entity_id.uuid);
    } else if (entity_id.entity_type === 'group') {
        entity_view_url.searchParams.append("group_uuid", entity_id.uuid);
    } else if (entity_id.entity_type === 'workout') {
        entity_view_url.searchParams.append("workout_uuid", entity_id.uuid);
    } else {
        throw new Error(`Unsupported entity type: ${entity_id.entity_type}`);
    }

    // let response = await fetch(entity_view_url).then((response: Response) => response.text())
    //                                             .then((payload: string) => JSON.parse(payload));

    let response = await fetch_json_w_retry(entity_view_url, fetch);
    
    // parse response
    let entity_views: EntityView = devalue.parse(response.entity_view);

    return {entity_view: entity_views} as EntityViewResponse;
}